<template>
	<div class="footer">
		<div class="logo"><img :src="$store.state.shopInfo.logo" /></div>
		<!-- pc style -->
		<div class="information">
			<div class="item">
				<div class="title">{{ $t('footer.aboutUs') }}</div>
				<div class="link" @click="$router.push('/ConnectUs')">{{ $t('nav.contact_us') }}</div>
				<div class="link" v-for="(val, i) in cate1" :key="i" @click="$router.push('/article?id=' + val.articleId)">{{ val.title }}</div>
			</div>
			<div class="item">
				<div class="title">{{ $t('footer.myAccount') }}</div>
				<div class="link" @click="$router.push('/OrderList')">{{ $t('userTab.order') }}</div>
				<div class="link" @click="$router.push('/Address')">{{ $t('userTab.address') }}</div>
				<div class="link" @click="$router.push('/Collect')">{{ $t('userTab.wish') }}</div>
				<div class="link" @click="$router.push('/Information')">{{ $t('userTab.setting') }}</div>
				<!-- <div class="link" @click="$router.push('/OrderList')">{{$t('userTab.history')}}</div> -->
				<!-- <div class="link" @click="$router.push('/OrderList')">{{$t('userTab.coupon')}}</div> -->
			</div>
			<div class="item">
				<div class="title">{{ $t('footer.information') }}</div>
				<div class="link" v-for="(val, i) in cate2" :key="i" @click="$router.push('/article?id=' + val.articleId)">{{ val.title }}</div>
			</div>
			<div class="subscribe">
				<div class="title">Suscríbete a nuestra Newsletter</div>
				<div class="line"></div>
				<div class="fill">
					<span>Rellena tus datos aquí</span>
					<i @click="$router.push('/ConnectUs')" class="el-icon-right"></i>
				</div>
				<div class="line"></div>
				<div class="social">
					<img src="../../assets/icon/facebook.png" @click="handleFaceBook()" />
					<img src="../../assets/icon/camera.png" @click="handleCamera()" />
					<!-- <img src="../../assets/icon/twitter.png"> -->
				</div>
			</div>
		</div>
		<!-- mobile style -->
		<div class="information-mobile">
			<el-collapse v-model="activeNames">
				<el-collapse-item :title="$t('footer.aboutUs')" name="1">
					<div class="link" @click="$router.push('/ConnectUs')">{{ $t('nav.contact_us') }}</div>
					<div class="link" v-for="(val, i) in cate1" :key="i" @click="$router.push('/article?id=' + val.articleId)">{{ val.title }}</div>
				</el-collapse-item>
				<el-collapse-item :title="$t('footer.myAccount')" name="2">
					<div class="link" @click="$router.push('/OrderList')">{{ $t('userTab.order') }}</div>
					<div class="link" @click="$router.push('/Address')">{{ $t('userTab.address') }}</div>
					<div class="link" @click="$router.push('/Collect')">{{ $t('userTab.wish') }}</div>
					<div class="link" @click="$router.push('/Information')">{{ $t('userTab.setting') }}</div>
				</el-collapse-item>
				<el-collapse-item :title="$t('footer.information')" name="3">
					<div class="link" v-for="(val, i) in cate2" :key="i" @click="$router.push('/article?id=' + val.articleId)">{{ val.title }}</div>
				</el-collapse-item>
			</el-collapse>
			<div class="subscribe">
				<div class="title">Suscríbete a nuestra Newsletter</div>
				<div class="line"></div>
				<div class="fill">
					<span>Rellena tus datos aquí</span>
					<i @click="$router.push('/ConnectUs')" class="el-icon-right"></i>
				</div>
				<div class="line"></div>
				<div class="social">
					<img src="../../assets/icon/facebook.png" @click="handleFaceBook()" />
					<img src="../../assets/icon/camera.png" @click="handleCamera()" />
					<!-- <img src="../../assets/icon/twitter.png"> -->
				</div>
			</div>
		</div>
		<cookie-law theme="dark-lime"></cookie-law>
	</div>
</template>

<script>
import Api from '../../api/request.js';
import CookieLaw from 'vue-cookie-law';
export default {
	name: 'Footer',
	components: { CookieLaw },
	data() {
		return {
			activeNames: '',
			cate1: [],
			cate2: []
		};
	},
	created() {
		this.getBottomCate();
	},
	methods: {
		getBottomCate() {
			Api.AboutUs.article({
				page: 1,
				pageLimit: 10,
				articleMenuId: 5
			}).then(res => {
				this.cate1 = res.data.data.list;
			});
			Api.AboutUs.article({
				page: 1,
				pageLimit: 10,
				articleMenuId: 1
			}).then(res => {
				console.log(res)
				this.cate2 = res.data.data.list;
			});
		},
		handleFaceBook() {
			window.location.href = 'https://www.facebook.com/smelltosmiles';
		},
		handleCamera() {
			window.location.href = 'https://www.instagram.com/smelltosmile/';
		}
	}
};
</script>

<style lang="less" scoped>
.footer {
	min-height: 420px;
	background: #f7f7f7;
	overflow: hidden;
	border-top: 1px solid #dfdfdf;
	.logo {
		margin: 35px 3% 30px;
		& > img {
			width: 100px;
		}
	}
	.information {
		display: flex;
		justify-content: space-between;
		padding: 0 3% 30px;
		> div {
			margin-right: 15px;
		}
		.item {
			flex: 1 1 20%;
			div {
				font-size: 16px;
				line-height: 19px;
				margin-bottom: 17px;
				font-family: GillSans;
				&.title {
					font-weight: 600;
					color: #4a4a4a;
				}
				&.link {
					cursor: pointer;
					color: #757575;
					&:hover {
						color: #000000;
						//text-decoration: underline;
					}
				}
			}
		}
		.subscribe {
			margin: -10px 0 0 20px;
			flex: 1 1 40%;
			.title {
				font-size: 30px;
				font-weight: 600;
				color: #4a4a4a;
				line-height: 36px;
				margin-bottom: 18px;
			}
			.line {
				height: 1px;
				background: #000000;
			}
			.fill {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 64px;
				span {
					font-size: 14px;
					font-family: GillSans;
					color: #000000;
				}
				i {
					font-size: 20px;
					color: #c7c7c7;
					cursor: pointer;
					&:hover {
						animation: move linear 0.5s;
					}
				}
				@keyframes move {
					30% {
						transform: translateX(5px);
					}
					60% {
						transform: translateX(0px);
					}
					90% {
						transform: translateX(5px);
					}
					100% {
						transform: translateX(0);
					}
				}
			}
			.social {
				margin-top: 24px;
				img {
					width: 24px;
					height: 24px;
					margin-right: 20px;
					cursor: pointer;
				}
			}
		}
	}
	.information-mobile {
		display: none;
		background: #fff;
		padding: 0 3% 30px;
		.title {
			font-weight: 600;
			height: 79px;
			color: #4a4a4a;
		}
		.link {
			cursor: pointer;
			height: 50px;
			color: #757575;
			&:hover {
				color: #000000;
				//text-decoration: underline;
			}
		}
		.subscribe {
			margin-top: 40px;
			flex: 1 1 40%;
			.title {
				font-size: 30px;
				font-weight: 600;
				color: #4a4a4a;
				line-height: 36px;
				margin-bottom: 18px;
			}
			.line {
				height: 1px;
				background: #000000;
			}
			.fill {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 64px;
				span {
					font-size: 14px;
					font-family: GillSans;
					color: #000000;
				}
				i {
					font-size: 20px;
					color: #c7c7c7;
					cursor: pointer;
					&:hover {
						animation: move linear 0.5s;
					}
				}
				@keyframes move {
					30% {
						transform: translateX(5px);
					}
					60% {
						transform: translateX(0px);
					}
					90% {
						transform: translateX(5px);
					}
					100% {
						transform: translateX(0);
					}
				}
			}
			.social {
				margin-top: 24px;
				img {
					width: 24px;
					height: 24px;
					margin-right: 20px;
					cursor: pointer;
				}
			}
		}
	}
	@media screen and (max-width: 900px) {
		.information {
			display: none;
		}
		.information-mobile {
			display: block;
		}
	}
}
/* 重写element样式 */
/deep/.el-collapse-item__header {
	height: 79px;
	font-weight: 600;
	color: #4a4a4a;
}
</style>
